import { uploadImgUrl } from '@/common/utils/config.js';
import mapComponent from '@/components/map.vue';
export default {
  components: {
    mapComponent
  },
  data() {
    return {
      isEdit: 2,
      uploadImgUrl: uploadImgUrl,
      releaseSuccessVisible: false,
      //发布协成功弹框
      dialogVisible: false,
      //发布协议弹框
      dialogImageUrl: '',
      //
      previewImgDialog: false,
      //预览图片

      countryList: [],
      //省市区列表
      classifyList: [],
      //所有分类列表

      province_id: '',
      //城市父id
      province_name: '',
      //城市父名字
      addr_id: '',
      //城市子id
      add_name: '',
      //城市子名字
      area_id: '',
      //第三级id
      area_name: '',
      //第三级名称
      cate_id: '',
      //分类id，
      parent_cate_id: '',
      //父级分类的id
      cate_name: '',
      //分类名称

      title: '',
      //标题
      detail: '',
      //详情描述
      isRead: false,
      //是否阅读协议
      content: '',
      inforData: [],
      //获取所有信息
      isHasAddress: false,
      //是否含有地址
      address: '',
      //地址
      addressItem: '',
      //详细的地址

      newAddress: '',
      //
      isShowVideo: false,
      //是否展示视频\

      img_list: [],
      //上传图片
      video_List: [],
      //上传视频
      videoUrl: '',
      //视频地址
      videoId: '',
      //视频id

      isFlag: false,
      information_id: '',
      previewFileList: [],
      //预览图片
      lang: ''
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang');
    this.getCountry();
    this.getCategoryList();
    this.getAll();
    this.information_id = this.$route.query.information_id;
    this.getEditInformationDetail();

    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.lang = e.newValue;
        this.getAll();
        this.getCategoryList();
      }
    });
  },
  methods: {
    getEditInformationDetail() {
      this.$http.editInformationDetail({
        information_id: this.information_id
      }).then(res => {
        if (res.code == 1) {
          this.inforDetail = res.data;
          this.title = this.inforDetail.title;
          this.cate_name = [this.inforDetail.parent_cate_id, this.inforDetail.cate_id];
          this.cate_id = this.inforDetail.cate_id;
          this.detail = this.inforDetail.detail;
          this.previewFileList = this.inforDetail.thumb_url.map(item => {
            return {
              name: item.id,
              url: item.path
            };
          });
          let newVideoUrl = this.inforDetail.video_url;
          if (newVideoUrl) {
            this.videoUrl = newVideoUrl[0].path;
            this.videoId = newVideoUrl[0].id;
          }
          this.province_id = this.inforDetail.province_id;
          this.province_name = this.inforDetail.province_name; //城市父名字
          this.addr_id = this.inforDetail.addr_id; //城市子id
          this.addr_name = this.inforDetail.addr_name;
          this.area_name = [this.inforDetail.province_id, this.inforDetail.addr_id, this.inforDetail.area_id];
          this.inforData = this.inforDetail.sku_info.map(item => {
            return {
              ...item,
              value: item.value_id == 0 ? item.value : item.value_id,
              valueId: item.value_id
            };
          });
          this.addressItem = this.inforData.find(item => item.type == 2);
          this.isHasAddress = this.addressItem ? true : false;
          // console.log("嘿嘿嘿", this.isHasAddress);
          // setTimeout(() => {
          this.address = this.addressItem.value;
          console.log("当前地址的", this.address);
          // }, 1500)
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getInforType() {
      this.$http.getCateType({
        cate_id: this.cate_id
      }).then(res => {
        if (res.code == 1) {
          this.inforData = res.data;
          this.inforData = this.inforData.map(item => {
            return {
              ...item,
              value: '',
              valueId: item.type_id
            };
          });
          this.isHasAddress = this.inforData.find(item => item.type == 2);
          this.isHasAddress = this.isHasAddress ? true : false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取省市区
    getCountry() {
      this.$http.getCountry().then(res => {
        if (res.code == 1) {
          this.countryList = res.data;
          console.log(this.countryList);
          let newCountryList;
          newCountryList = this.countryList.map(item => {
            return {
              label: item.name,
              value: item.id,
              children: item.children.map(item2 => {
                return {
                  label: item2.name,
                  value: item2.id,
                  children: item2.children.map(item3 => {
                    return {
                      label: item3.name,
                      value: item3.id
                    };
                  })
                };
              })
            };
          });
          this.countryList = JSON.parse(JSON.stringify(newCountryList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取所有分类
    getCategoryList() {
      this.$http.getCate({
        is_hot: 0
      }).then(res => {
        if (res.code == 1) {
          this.classifyList = res.data;
          let newClassifyList = this.classifyList.map(item => {
            return {
              label: item.name,
              value: item.id,
              children: item.children.map(item2 => {
                return {
                  label: item2.name,
                  value: item2.id
                };
              })
            };
          });
          this.classifyList = JSON.parse(JSON.stringify(newClassifyList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cascaderChangeClick(val, type) {
      switch (type * 1) {
        case 1:
          // console.log(val);
          this.parent_cate_id = val[0];
          this.cate_id = val.length ? val[1] : '';
          this.getInforType();
        case 2:
          // if (val.length > 0) {
          // 	this.province_id = val[0]; //省id
          // 	this.addr_id = val[1]; //市id
          // 	this.area_id = val[2]; //分类id，
          // } else {
          // 	this.province_id = '';
          // 	this.addr_id = '';
          // 	this.area_id = '';
          // }
          break;
      }
    },
    // 获取巴拿马协议
    getAll() {
      this.$http.getArticle({
        category_id: this.lang == 'zh' ? 32 : 33
      }).then(res => {
        if (res.code == 1) {
          this.content = res.data.content;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.img_list = fileList;
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },
    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      console.log("文件类型", fileType);
      if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg' || fileType === '.bmp' || fileType === '.gif') {} else {
        this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
        return false;
      }
    },
    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    // 
    beforeUpload2(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) == -1) {
        this.$message.error(this.$t('pleaseUploadTheCorrectVideoFormat'));
        return false;
      }
      if (!isLt10M) {
        this.$message.error(this.$t('theSizeOfUploadedVideoCannotExceed'));
        return false;
      }
    },
    onSuccess(res, file) {
      this.videoUrl = res.data[0].path;
      this.videoId = res.data[0].id;
    },
    // 清除之前上传的视频
    removeVideo() {
      this.videoUrl = '';
      this.videoId = '';
      this.$refs.uploadVideo.clearFiles();
    },
    // 获取当前位置
    getAddressEvent(e) {
      console.log(e);
      this.address = e;
    },
    // 子组件向父组件传值
    changeIsEdit() {
      this.isEdit = 1;
    },
    // 立即发布
    toReleaseClick() {
      if (this.isFlag) return;
      if (!this.title) {
        this.$message.error(this.$t('tips.pleaseEnterTitle'));
        return;
      }

      //分类
      this.parent_cate_id = this.cate_name[0];
      this.cate_id = this.cate_name.length > 1 ? this.cate_name[1] : '';
      if (!this.cate_id) {
        this.$message.info(this.$t('tips.pleaseEnterSelectionCategory'));
        return;
      }

      // 省市区
      this.province_id = this.area_name[0];
      this.addr_id = this.area_name[1];
      this.area_id = this.area_name.length > 2 ? this.area_name[2] : '';
      if (!this.area_id) {
        this.$message.info(this.$t('release.selectCity'));
        return;
      }
      if (!this.detail) {
        this.$message.info(this.$t('tips.pleaseEnterDetailedDescription'));
        return;
      }

      // 处理图片
      let newImgList;
      console.log("当前的图片", this.img_list);
      if (this.img_list.length > 0) {
        newImgList = this.img_list.map(item => {
          if (Object.prototype.hasOwnProperty.call(item, "response")) {
            return item.response.data[0].id;
          } else {
            return item.name;
          }
        }).join(',');
        if (newImgList.length == 0) {
          this.$message.info(this.$t('tips.atLeastOnePhoto'));
          return;
        }
      } else {
        newImgList = this.previewFileList.map(item => item.name).join(',');
      }
      this.inforData.forEach(item => {
        if (item.type == 2) {
          item.value = this.address;
        }
      });
      for (var i = 0; i < this.inforData.length; i++) {
        if (this.inforData[i].is_must == 1) {
          if (!this.inforData[i].value) {
            this.$message.info(this.inforData[i].type_name + this.$t('tips.cannotEmpty'));
            return;
          }
        }
      }
      let newInforData = this.inforData.map(item => {
        return {
          category_type_id: item.id,
          information_type: item.type,
          value: item.type == 3 ? item.value : item.value,
          type_id: item.type_id
        };
      });
      console.log("哈哈哈", newInforData);
      if (!this.isRead) {
        this.$message.info(this.$t('tips.pleaseCheckThePanamaInformationReleaseAgreement'));
        return;
      }
      this.isFlag = true;
      console.log("啦啦啦啦啦");
      let data = {
        id: this.information_id,
        title: this.title,
        parent_cate_id: this.parent_cate_id,
        cate_id: this.cate_id,
        detail: this.detail,
        thumb: newImgList,
        video: this.videoId,
        province_id: this.province_id,
        addr_id: this.addr_id,
        area_id: this.area_id,
        information_data: JSON.stringify(newInforData)
      };
      this.$http.editInformation(data).then(res => {
        if (res.code == 1) {
          this.isFlag = false;
          this.releaseSuccessVisible = true;
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      });
    },
    // 所有内容清空
    clearContent() {
      this.title = '';
      this.cate_id = '';
      this.cate_name = '';
      this.detail = '';
      this.videoId = '';
      this.videoUrl = '';
      this.province_id = '';
      this.province_name = '';
      this.addr_id = '';
      this.add_name = '';
      this.area_id = '';
      this.area_name = '';
      this.img_list = [];
      this.inforData = [];
      this.$refs.uploadVideo.clearFiles();
      this.$refs.uploadImg.clearFiles();
      this.isRead = false;
      this.isHasAddress = false;
    }
  }
};