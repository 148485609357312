var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "release"
  }, [_c('div', {
    staticClass: "login_top"
  }, [_vm._m(0), _c('div', {
    staticClass: "login_title"
  }, [_vm._v(_vm._s(_vm.$t('modifyReleaseInfo')))])]), _c('div', {
    staticClass: "release_input"
  }, [_c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('release.title')))]), _c('div', {
    staticClass: "input_content"
  }, [_c('el-input', {
    staticStyle: {
      "width": "420px"
    },
    attrs: {
      "type": "",
      "placeholder": _vm.$t('release.inputTitle'),
      "clearable": ""
    },
    model: {
      value: _vm.title,
      callback: function ($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  })], 1)]), _c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('release.type')))]), _c('div', {
    staticClass: "input_content"
  }, [_c('el-cascader', {
    staticStyle: {
      "width": "420px"
    },
    attrs: {
      "clearable": "",
      "options": _vm.classifyList,
      "placeholder": _vm.$t('release.selectType')
    },
    on: {
      "change": function ($event) {
        return _vm.cascaderChangeClick($event, 1);
      }
    },
    model: {
      value: _vm.cate_name,
      callback: function ($$v) {
        _vm.cate_name = $$v;
      },
      expression: "cate_name"
    }
  })], 1)]), _c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('release.cityLive')))]), _c('div', {
    staticClass: "input_content"
  }, [_c('el-cascader', {
    staticStyle: {
      "width": "420px"
    },
    attrs: {
      "clearable": "",
      "options": _vm.countryList,
      "placeholder": _vm.$t('other.selectCity')
    },
    on: {
      "change": function ($event) {
        return _vm.cascaderChangeClick($event, 2);
      }
    },
    model: {
      value: _vm.area_name,
      callback: function ($$v) {
        _vm.area_name = $$v;
      },
      expression: "area_name"
    }
  })], 1)]), _vm.isHasAddress ? [_c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('detailAddress')))]), _c('div', {
    staticClass: "input_content"
  }, [_c('el-input', {
    staticStyle: {
      "width": "420px"
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('release.pleaseSelectRegion'),
      "disabled": true
    },
    model: {
      value: _vm.address,
      callback: function ($$v) {
        _vm.address = $$v;
      },
      expression: "address"
    }
  })], 1)]), _c('div', {
    staticClass: "address",
    staticStyle: {
      "width": "800px",
      "height": "400px",
      "margin-bottom": "24px"
    }
  }, [_c('mapComponent', {
    attrs: {
      "isEdit": _vm.isEdit
    },
    on: {
      "changeIsEdit": _vm.changeIsEdit,
      "getAddressEvent": _vm.getAddressEvent
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('releaseContent')))]), _c('div', {
    staticClass: "input_textarea"
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": true,
      "placeholder": _vm.$t('release.pleaseEnterDescriptionDetails')
    },
    model: {
      value: _vm.detail,
      callback: function ($$v) {
        _vm.detail = $$v;
      },
      expression: "detail"
    }
  })], 1)]), _c('div', {
    staticClass: "video"
  }, [_c('el-upload', {
    ref: "uploadVideo",
    attrs: {
      "data": {
        'dir': 'file'
      },
      "action": _vm.uploadImgUrl,
      "list-type": "picture-card",
      "limit": 1,
      "before-upload": _vm.beforeUpload2,
      "on-success": _vm.onSuccess,
      "show-file-list": false
    }
  }, [_c('span', {
    staticClass: "el-upload-action"
  }), _c('i', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.videoUrl,
      expression: "!videoUrl"
    }],
    staticClass: "el-icon-plus"
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.videoUrl,
      expression: "videoUrl"
    }],
    staticClass: "upload_video"
  }, [_c('video', {
    staticClass: "video",
    attrs: {
      "controls": "controls",
      "src": _vm.videoUrl
    }
  }), _c('i', {
    staticClass: "el-icon-remove",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeVideo.apply(null, arguments);
      }
    }
  })]), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.$t('other.uploadVideo')))])])], 1), _c('div', {
    staticClass: "all_imgs"
  }, [_c('el-upload', {
    ref: "uploadImg",
    attrs: {
      "action": _vm.uploadImgUrl,
      "list-type": "picture-card",
      "on-preview": _vm.handlePictureCardPreview,
      "on-remove": _vm.handleRemove,
      "before-upload": _vm.beforeUpload,
      "on-change": _vm.onChange,
      "file-list": _vm.previewFileList,
      "multiple": true
    }
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _c('div', {
    staticClass: "el-upload__tip",
    attrs: {
      "slot": "tip"
    },
    slot: "tip"
  }, [_vm._v(_vm._s(_vm.$t('other.uploadPhoto')))])]), _c('el-dialog', {
    attrs: {
      "visible": _vm.previewImgDialog,
      "modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.previewImgDialog = $event;
      }
    }
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.dialogImageUrl,
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "input_item"
  }, [_c('div', {
    staticClass: "input_title"
  }, [_vm._v(_vm._s(_vm.$t('basicInfo')))])]), _c('div', {
    staticClass: "basic_infor"
  }, _vm._l(_vm.inforData, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "basic_item"
    }, [item.type != 2 ? _c('div', {
      staticClass: "basic_name"
    }, [_vm._v(_vm._s(item.type_name))]) : _vm._e(), item.type == 1 ? _c('div', {
      staticClass: "basic_input"
    }, [_c('el-input', {
      attrs: {
        "type": item.is_number == 0 ? 'text' : 'number',
        "placeholder": _vm.$t('release.pleaseInput') + item.type_name,
        "clearable": ""
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.type == 3 ? _c('div', {
      staticClass: "basic_input"
    }, [_c('el-select', {
      attrs: {
        "clearable": "",
        "placeholder": _vm.$t('release.pleaseSelect') + item.type_name
      },
      model: {
        value: item.value,
        callback: function ($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }, _vm._l(item.type_data, function (item2, index2) {
      return _c('el-option', {
        key: index2,
        attrs: {
          "label": item2.name,
          "value": item2.id
        }
      });
    }), 1)], 1) : _vm._e()]);
  }), 0)], 2), _c('div', {
    staticClass: "release_bottom"
  }, [_c('div', {
    staticClass: "release_btn",
    on: {
      "click": _vm.toReleaseClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('release.immediatelyRelease')) + " ")]), _c('div', {
    staticClass: "release_tip",
    on: {
      "click": function ($event) {
        _vm.isRead = !_vm.isRead;
      }
    }
  }, [_c('div', {
    staticClass: "img"
  }, [_vm.isRead ? _c('img', {
    attrs: {
      "src": require("@/assets/login/check.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/login/un_select.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t('release.haveReadAndAgree'))), _c('span', {
    on: {
      "click": function ($event) {
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v("《" + _vm._s(_vm.$t('release.PanamaAgreement')) + "》")])])])])]), _c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.dialogVisible,
      "width": "38%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('div', {
    staticClass: "title_all"
  }, [_c('div', {
    staticClass: "title_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]), _c('div', [_vm._v(_vm._s(_vm.$t('title.detail')))])])]), _c('div', {
    staticClass: "content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })]), _c('div', {
    staticClass: "success_dialog"
  }, [_c('el-dialog', {
    attrs: {
      "title": "",
      "visible": _vm.releaseSuccessVisible,
      "width": "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.releaseSuccessVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "release_success"
  }, [_c('div', {
    staticClass: "success_img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/home_icons/release_success.png")
    }
  })]), _c('div', {
    staticClass: "success_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('release.submitSuccess')) + " ")]), _c('div', {
    staticClass: "success_tip"
  }, [_vm._v(_vm._s(_vm.$t('release.waitPatient')))])])])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/flag.png")
    }
  })]);
}];
export { render, staticRenderFns };